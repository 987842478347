import React from "react";
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
	AccordionItemState,
} from "react-accessible-accordion";
import { ArrowDownIcon, ArrowRightIcon1 } from "assets/svg";

const Faq = () => {
	return (
		<Accordion allowMultipleExpanded allowZeroExpanded className="max-w-[1200px] mx-[auto]">
			<AccordionItem className="mb-[30px]">
				<AccordionItemHeading>
					<AccordionItemButton className="questionbg">
						<div className="justify-between d-flex">
							<h2 className="question-heading">What is ClassCube Pro</h2>
							<AccordionItemState>{({ expanded }) => (expanded ? <ArrowDownIcon /> : <ArrowRightIcon1 />)}</AccordionItemState>
						</div>
					</AccordionItemButton>
				</AccordionItemHeading>
				<AccordionItemPanel>
					<p className="question-subheading">
						Classcube Pro is a cutting-edge Learning Management System designed to provide a comprehensive online learning environment. It
						offers a range of tools and features to facilitate effective learning, course management, and collaboration for educators and
						learners.
					</p>
				</AccordionItemPanel>
			</AccordionItem>
			<AccordionItem className="mb-[30px]">
				<AccordionItemHeading>
					<AccordionItemButton className="questionbg">
						<div className="justify-between d-flex">
							<h2 className="question-heading">How can I get started with ClassCube Pro?</h2>
							<AccordionItemState>{({ expanded }) => (expanded ? <ArrowDownIcon /> : <ArrowRightIcon1 />)}</AccordionItemState>
						</div>
					</AccordionItemButton>
				</AccordionItemHeading>
				<AccordionItemPanel>
					<p className="question-subheading">
						Simply sign up for a ClassCube Pro account, and you'll gain access to a user-friendly dashboard where you can start creating
						and publishing courses.
					</p>
				</AccordionItemPanel>
			</AccordionItem>
			<AccordionItem className="mb-[30px]">
				<AccordionItemHeading>
					<AccordionItemButton className="questionbg">
						<div className="justify-between d-flex">
							<h2 className="question-heading">What types of content can I create on ClassCube Pro?</h2>
							<AccordionItemState>{({ expanded }) => (expanded ? <ArrowDownIcon /> : <ArrowRightIcon1 />)}</AccordionItemState>
						</div>
					</AccordionItemButton>
				</AccordionItemHeading>
				<AccordionItemPanel>
					<p className="question-subheading">
						You can create a wide range of content, including text-based lessons, videos, audio, quizzes, and more. ClassCube Pro offers
						diverse multimedia options to enhance your courses.
					</p>
				</AccordionItemPanel>
			</AccordionItem>
			<AccordionItem className="mb-[30px]">
				<AccordionItemHeading>
					<AccordionItemButton className="questionbg">
						<div className="justify-between d-flex">
							<h2 className="question-heading">How do I earn money with ClassCube Pro?</h2>
							<AccordionItemState>{({ expanded }) => (expanded ? <ArrowDownIcon /> : <ArrowRightIcon1 />)}</AccordionItemState>
						</div>
					</AccordionItemButton>
				</AccordionItemHeading>
				<AccordionItemPanel>
					<p className="question-subheading">
						ClassCube Pro offers a transparent revenue-sharing model. When learners enroll in your courses, you earn a portion of the
						revenue generated.
					</p>
				</AccordionItemPanel>
			</AccordionItem>
			{/* <AccordionItem className="mb-[30px]">
				<AccordionItemHeading>
					<AccordionItemButton className="questionbg">
						<div className="justify-between d-flex">
							<h2 className="question-heading">Is there a cost to use ClassCube Pro?</h2>
							<AccordionItemState>{({ expanded }) => (expanded ? <ArrowDownIcon /> : <ArrowRightIcon1 />)}</AccordionItemState>
						</div>
					</AccordionItemButton>
				</AccordionItemHeading>
				<AccordionItemPanel>
					<p className="question-subheading">I don’t know this answer. I’ll ask Maxwell tomorrow</p>
				</AccordionItemPanel>
			</AccordionItem> */}
			<AccordionItem className="mb-[30px]">
				<AccordionItemHeading>
					<AccordionItemButton className="questionbg">
						<div className="justify-between d-flex">
							<h2 className="question-heading">How does collaboration work on ClassCube Pro?</h2>
							<AccordionItemState>{({ expanded }) => (expanded ? <ArrowDownIcon /> : <ArrowRightIcon1 />)}</AccordionItemState>
						</div>
					</AccordionItemButton>
				</AccordionItemHeading>
				<AccordionItemPanel>
					<p className="question-subheading">
						Collaboration on ClassCube Pro allows educators and content creators to work together on course development. You can invite
						collaborators, share resources, and co-create content seamlessly.
					</p>
				</AccordionItemPanel>
			</AccordionItem>
			<AccordionItem className="mb-[30px]">
				<AccordionItemHeading>
					<AccordionItemButton className="questionbg">
						<div className="justify-between d-flex">
							<h2 className="question-heading">Is there a revenue-sharing model for collaborative courses?</h2>
							<AccordionItemState>{({ expanded }) => (expanded ? <ArrowDownIcon /> : <ArrowRightIcon1 />)}</AccordionItemState>
						</div>
					</AccordionItemButton>
				</AccordionItemHeading>
				<AccordionItemPanel>
					<p className="question-subheading">
						Yes, ClassCube Pro provides a transparent revenue-sharing model for collaborative courses. Collaborators can earn a fair share
						of the revenue generated by the course.
					</p>
				</AccordionItemPanel>
			</AccordionItem>
		</Accordion>
	);
};
export default Faq;
