import React from "react";
import LinesEllipsis from "react-lines-ellipsis";
// import Progress from './Progress';
import { Link } from "react-router-dom";

const PaidCourses = ({ course }) => {
	return (
		<div className="overflow-hidden rounded-lg">
			<Link className="w-full" to={`/courses/${course?.slug}`}>
				<img src={`https://${course?.courseImg}`} className="w-full h-[200px] object-cover" alt="course" />
			</Link>
			<div className="p-[12px] [&>*]:mb-3">
				<Link className="block" to={`/courses/${course?.slug}`}>
					<LinesEllipsis text={course?.name} className="text-black text-[1.7rem] font-bold" maxLine="2" ellipsis="..." basedOn="letters" />
				</Link>
				<p className="text-grey text-[1.4rem]">{course?.creator?.fullName}</p>
			</div>
		</div>
	);
};

export default PaidCourses;
