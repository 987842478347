import { CoursesGrid, PaidCourses, TopCourses } from "components";
import { useAppContext } from "context/AppContext";
import { Link } from "react-router-dom";
import { featuredCourses, getMyCourses, topCourses } from "api";
import { useQuery } from "@tanstack/react-query";
import { CardSkeleton } from "skeleton";
import { Helmet } from "react-helmet";
import landingimg from "assets/landingimg.png";
import businessimg from "assets/business-img.png";
import Faq from "./Faq";

const Landing = () => {
	const { isLoggedIn, user } = useAppContext();
	const { isLoading: topCoursesIsLoading, data: topCoursesData } = useQuery({
		queryKey: ["top-courses"],
		queryFn: topCourses,
	});

	if (isLoggedIn && user) {
		return <AuthLanding loading={topCoursesIsLoading} data={topCoursesData} />;
	} else {
		return <NoAuthLanding loading={topCoursesIsLoading} data={topCoursesData} />;
	}
};

const NoAuthLanding = ({ loading, data }) => {
	// const [selectedCategories, setSelectedCategories] = useState(null);
	// const [selectedCategoriesName, setSelectedCategoriesName] = useState(null);

	// const { data: categoriesData } = useQuery({
	// 	queryKey: ["categories"],
	// 	queryFn: getCategories,
	// 	suspense: true,
	// });

	// useEffect(() => {
	// 	setSelectedCategories(categoriesData?.data?.categories[0]?.id);
	// 	setSelectedCategoriesName(categoriesData?.data?.categories[0]?.id);
	// }, [categoriesData]);

	const { isLoading: featuredIsLoading, data: feturedCourses } = useQuery({
		queryKey: ["featured-courses"],
		queryFn: featuredCourses,
	});

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Classcube Pro | Home </title>
			</Helmet>
			<div className="bg-[#EEF6FF] px-size pt-28  mb-20 landing-contain">
				<div className="row max-w-[1400px] mx-auto">
					<div className="col-lg-6">
						<div className="flex flex-col gap-5 max-w-[1400px] pb-[55px] mx-auto">
							<h1 className="text-primaryColor text-[4.6rem] md:text-[5rem] md:max-w-[675px] font-bold">
								All the Courses You Need in One Place
							</h1>
							<p className="text-grey text-[2rem] font-light md:max-w-[500px]">
								Explore a vast library of courses, gain valuable skills, and elevate your career and personal development.
							</p>
							<Link to="/courses" className="bg-accent text-white w-[180px] text-[1.6rem] p-[12px] text-center rounded-md my-3 ">
								Explore Our Courses
							</Link>
						</div>
					</div>

					<div className="col-lg-6">
						<img src={landingimg} alt="" className="landing-img" />
					</div>
				</div>
			</div>
			<div className="max-w-[1400px] mx-auto px-[3rem]">
				{/* <div className="flex flex-nowrap gap-4 mb-20 border-b border-[#E7E5EA] max-w-[90vw] overflow-x-auto">
					{categoriesData?.data?.categories.map((category, index) => (
						<div
							key={index}
							className={`px-4 py-4 cursor-pointer min-w-fit capitalize hover:text-accent ${
								selectedCategories === category.id ? "text-accent border-b-2 border-b-accent" : "text-grey"
							}`}
							tabIndex={0}
							onClick={() => {
								setSelectedCategories(category.id);
								setSelectedCategoriesName(category.id);
							}}
						>
							{category.name}
						</div>
					))}
				</div> */}

				<CoursesGrid title="Featured Courses" isLoading={featuredIsLoading} data={feturedCourses} />
				<TopCourses title="Top Courses" isLoading={loading} data={data} />
			</div>
			<div className="py-28 mb-32 bg-[#F3F9FF]">
				<div className="d-flex justify-between items-center  max-w-[1400px] px-[3rem] mx-auto our-faq">
					<div className="">
						<h2 className="classcube-for-business">Classcube For Business</h2>
						<h6 className="classcube-for-business-sub">
							ClassCube Business is a powerful and comprehensive learning management system that puts you in control of your online
							education platform. You can tailor the interface, functions, and even the URL to align with your brand.
						</h6>
					</div>
					<div className="w-[65rem]">
						<img src={businessimg} alt="" />
					</div>
				</div>
			</div>
			<div className=" max-w-[1400px] mx-auto py-10 mb-10 px-[3rem]">
				<h2 className="faq-header">Frequently Asked Questions</h2>
				<Faq />
			</div>
		</>
	);
};

const AuthLanding = ({ loading, data }) => {
	const { user } = useAppContext();

	const { isLoading: myCourseIsLoading, data: myCourseData } = useQuery({
		queryKey: ["my-courses"],
		queryFn: getMyCourses,
	});

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Classcube Pro | Home </title>
			</Helmet>
			<div className="bg-[#EEF6FF] px-size pt-28 mb-20 landing-contain">
				<div className="row max-w-[1400px] mx-auto">
					<div className="col-lg-6">
						<div className="flex flex-col gap-5 max-w-[1400px] mx-auto pb-[55px]">
							<h1 className="text-primaryColor text-[5.6rem] md:max-w-[705px] font-bold">
								Digital Learning and Management for Schools
							</h1>
							<p className="text-grey text-[2rem] font-light md:max-w-[500px]">
								Explore a vast library of courses, gain valuable skills, and elevate your career and personal development.
							</p>
							<div className="flex gap-5">
								<Link to="/courses" className="bg-[#142D58] text-white text-[1.6rem] p-[12px] px-5 rounded-md">
									Marketplace
								</Link>
							</div>
						</div>
					</div>

					<div className="col-lg-6">
						<img src={landingimg} alt="" className="landing-img" />
					</div>
				</div>
			</div>
			<div className="px-[3rem]">
				{myCourseIsLoading && (
					<>
						<div className="flex items-center justify-between  mb-[5rem] mt-[2rem]">
							<h1 className="text-[2.8rem] text-[#2A3037] capitalize font-bold">Continue Learning, {user?.firstName}</h1>
							<Link to="/my-courses" className="text-accent hover:underline font-bold text-[2rem]">
								My Courses
							</Link>
						</div>
						<div className="grid gap-x-[15px] gap-y-[25px] min-[550px]:grid-cols-2 min-[1090px]:grid-cols-3">
							<CardSkeleton count={6} />
						</div>
					</>
				)}
				{myCourseData?.data?.courses.length < 1 && (
					<div>
						<div className="flex items-center flex-col gap-7 mb-[5rem] mt-[15rem]">
							<p className="text-grey text-[2rem] font-light">Welcome {user?.firstName}</p>
							<p className="text-grey text-[2rem] font-light">You haven't purchased any course</p>
							<Link to="/my-courses" className="explore-link text-grey text-[1.6rem] p-[12px] px-5 rounded-md">
								Explore Now
							</Link>
						</div>
					</div>
				)}
				<div className="max-w-[1400px] mx-auto min-h-[30vh]">
					{myCourseData?.data?.courses.length > 0 && (
						<div>
							<div className="flex items-center justify-between  mb-[5rem] mt-[2rem]">
								<h1 className="text-[2.8rem] text-[#2A3037] capitalize font-bold">Continue Learning, {user?.firstName}</h1>
								<Link to="/my-courses" className="text-accent hover:underline font-bold text-[2rem] max-[425px]:hidden">
									My Courses
								</Link>
							</div>
							<div className="grid md:grid-cols-[repeat(auto-fill,_minmax(300px,1fr))] sm:grid-cols-[repeat(auto-fit,_minmax(220px,1fr))] grid-cols-[repeat(auto-fit,_minmax(200px,1fr))] gap-10">
								{myCourseData?.data?.courses?.map((course, index) => (
									<PaidCourses key={index} course={course.course} />
								))}
							</div>
						</div>
					)}
					<div className="my-[60px]">
						<TopCourses title="Top Courses" isLoading={loading} data={data} />
					</div>
				</div>
			</div>
		</>
	);
};

export default Landing;
