import React, { useState } from "react";
import Logo from "assets/logo-inverted.png";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { ArrowHeadLeftIcon } from "assets/svg";
import { Progress } from "components";
import { BiX } from "react-icons/bi";
import { generateProfileImage } from "assets/functions";
import { useAppContext } from "context/AppContext";
import { useCookies } from "react-cookie";

const Topnav = ({ course, percentage }) => {
	const { user } = useAppContext();
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 720px)" });
	const [isNavOpen, setIsNavOpen] = useState(false);
	const [, , removeCookie] = useCookies();

	const profileImage = generateProfileImage(user.firstName, user.lastName);

	const handleLogout = () => {
		removeCookie("jwt");
		localStorage.clear();
		window.location.reload();
	};

	return (
		<nav className="py-[18px] px-size -mx-size !bg-primary text-white mb-10">
			<div className="flex items-center justify-between max-w-[1440px] mx-auto">
				<div className="flex items-center gap-5 divide-x divide-white">
					{isTabletOrMobile ? (
						<Link to={-1}>
							<ArrowHeadLeftIcon />
						</Link>
					) : (
						<Link to="/">
							<img className="w-[137px] block" src={Logo} alt="logo" />
						</Link>
					)}
					<p className="text-[1.6rem]  font-thin pl-[1.5rem]">{course?.name}</p>
				</div>
				<div className="flex items-center gap-5 720px]:hidden">
					<Progress percentage={percentage || 0} showTop />
					<Link to="#" onClick={() => setIsNavOpen(!isNavOpen)}>
						{profileImage}
					</Link>
				</div>
				{isNavOpen && (
					<div className="relative">
						<div
							className={`flex justify-end fixed inset-0 bg-gray-900 bg-opacity-50 z-50 transition-transform ${
								isNavOpen ? "translate-x-0" : "-translate-x-full"
							} ease-in-out duration-300`}
						>
							<div className="w-[40%] bg-white h-full">
								<div className="flex justify-between px-4 py-5 border-b-[1px] ">
									<div className="flex items-center gap-5 ">
										{profileImage}
										<p className="text-gray text-[1.6rem] font-semibold">
											Hi, {user.firstName} {user.lastName}
											<br />
											<span className=" text-[1.2rem] font-500">Welcome</span>
										</p>
									</div>
									<BiX fontSize={25} className="cursor-pointer text-grey " onClick={() => setIsNavOpen(!isNavOpen)} />
								</div>

								<div className="border-b-[1px] py-4 px-5 flex flex-col">
									<Link to="/settings" className="text-grey hover:underline text-[1.4rem] mb-4">
										Account Settings
									</Link>
									<Link to="/notifications" className="text-grey hover:underline text-[1.4rem] mb-4">
										Notifications
									</Link>
									<Link to="/my-courses" className="text-grey hover:underline text-[1.4rem] mb-4">
										My Courses
									</Link>
									<Link to="/courses" className="text-grey hover:underline text-[1.4rem] mb-4">
										MarketPlace
									</Link>
								</div>
								<div className="border-b-[1px] py-4 px-5 flex flex-col">
									<a
										href="https://enterprise.classcube.online/creator/signup"
										className="text-grey hover:underline hover:text-accent text-[1.4rem] mb-4"
									>
										Become a Creator
									</a>
									<Link to="#" className="text-grey hover:underline hover:text-accent text-[1.4rem] mb-4">
										Help
									</Link>
									<Link to="#" className="text-grey hover:underline hover:text-accent text-[1.4rem] mb-4">
										Tell a Friend
									</Link>
								</div>
								<div className="flex flex-col items-start px-5 py-4">
									<button
										to="#"
										className="text-accent hover:underline hover:text-accent text-[1.4rem] mb-4"
										onClick={handleLogout}
									>
										Logout
									</button>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</nav>
	);
};

export default Topnav;
