import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { SkeletonTheme } from "react-loading-skeleton";
import "./App.css";
import { CourseDetails, Landing, MarketPlace, MyCourses, SetPassword, StudentSignUp, StudentsSignIn, Notifications, Settings } from "./pages";
import {
	AppLayout, //? adds bottom nav
	TopNavLayout,
	Redirect, //? used to check if page needs user to be logged in
} from "layouts";
import Loader from "components/Loader";
import ErrorPage from "ErrorBoundary";

function App() {
	return (
		<Suspense fallback={<Loader />}>
			<SkeletonTheme>
				<BrowserRouter>
					<Routes>
						<Route element={<AppLayout />} errorElement={<ErrorPage />}>
							<Route element={<TopNavLayout />}>
								<Route path="/" element={<Landing />} />
								<Route element={<Redirect />}>
									<Route path="/my-courses" element={<MyCourses />} />
									<Route path="/settings" element={<Settings />} />
									<Route path="/notifications" element={<Notifications />} />
								</Route>
								<Route path="/courses">
									<Route index element={<MarketPlace />} />
								</Route>
							</Route>
							<Route path="/courses">
								<Route path=":slug" element={<CourseDetails />} />
							</Route>
						</Route>
						<Route element={<Redirect noAuth />}>
							<Route path="/login" element={<StudentsSignIn />} />
							<Route path="/register" element={<StudentSignUp />} />
							<Route path="/setpassword" element={<SetPassword />} />
						</Route>
					</Routes>
				</BrowserRouter>
			</SkeletonTheme>
		</Suspense>
	);
}

export default App;
