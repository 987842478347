import { renderErrorMessage, renderSuccessMessage } from "../assets/functions";
import axios from "axios";
import Cookies from "js-cookie";

const api = axios.create({
	baseURL: process.env.NODE_ENV === "development" ? `${process.env.REACT_APP_LOCAL_BASEURL}` : `${process.env.REACT_APP_PROD_BASEURL}`,
	headers: {
		"Content-Type": "application/json",
		"Access-Control-Allow-Credentials": true,
	},
	withCredentials: true,
});

api.interceptors.request.use((config) => {
	config.baseURL = config.baseURL.concat("/revocube_main");
	return config;
}, null);

api.interceptors.response.use(
	(response) => response.data,

	(error) => {
		if (error.response.status === 401) {
			renderErrorMessage(error.response.data.message).then(() => {
				Cookies.remove("jwt");
				window.location.href = "/";
			});
		} else if (error.response.status === 503) {
			renderSuccessMessage(error.response.data.message);
			throw new Error(error.response.data.message ?? "Account Created");
		} else {
			renderErrorMessage(error.response.data.message);
			throw new Error(error.response.data.message, {
				cause: error.response.status,
			});
		}
	}
);

//* Authentication
export const signIn = (data) => {
	return api.post("/auth/student/login", data);
};

export const signUp = (data) => {
	return api.post("/auth/student/signup", data);
};
export const forgotPassword = (data) => {
	return api.post("/auth/student/forgotpassword", data);
};
export const resetPassword = (data) => {
	return api.post("auth/student/resetpassword/", data);
};
export const setPassword = ({ token, data }) => {
	return api.post(`/auth/student/resetpassword/${token}`, data);
};
export const updatePassword = (data) => {
	return api.patch(`/auth/student/updatePassword`, data);
};

// * Courses
export const getCourse = (id) => api.get(`/app/courses/${id}`);

export const getCourseBySlug = (slug) => api.get(`/app/courses/slug/${slug}`);

export const getMyCourses = () => api.get(`/student/mycourses`);

export const getMyCoursesPaginate = (page, limit) => {
	return api.get(`/student/paginate-mycourses`, {
		params: { page, limit },
	});
};

export const getMyCourseById = (id) => api.get(`/student/mycourses/${id}`);

export const getMyCourseBySlug = (slug) => api.get(`/student/mycourses/${slug}`);

export const getCategories = ({ all = 0 }) => {
	return api.get("/app/categories", {
		params:
			all === 1
				? {
					all: 1,
				}
				: null,
	});
};
export const filterCourses = (search, rating = 0, category, courseLevel, page, limit) => {
	return api.get("/app/courses", {
		params: {
			rating,
			name: search,
			category,
			courseLevel,
			page,
			limit,
		},
	});
};

export const getUserNotifications = (page, limit) => api.get("/student/notifications", { params: { page, limit } });

// export const getCourses = (categories) => {
// 	return api.get("/app/courses", {
// 		params: {
// 			category: categories,
// 			isFeatured: 1,
// 			// rating: 1,
// 		},
// 	});
// };

export const featuredCourses = () => {
	return api.get("/app/courses/featured");
};

export const getstudentprofile = () => {
	return api.get("/student/profile");
};

export const updatestudentprofile = (data) => {
	return api.patch("/student/profile/", data, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
};

export const topCourses = () => {
	return api.get("/app/rated/courses", {
		params: {
			startRate: 3,
			endRate: 4.7,
			limit: 8,
		},
	});
};

//* Payment
export const buyCourse = (courseId) => {
	return api.get(`/payment/init-payment/${courseId}`);
};

export const verifyPayment = ({ transactionReference }) => {
	return api.get(`/payment/verify-payment/${transactionReference}`);
};

export const claimCourse = (courseId) => {
	return api.get(`/payment/claimCourse/${courseId}`);
};

//* Course Reviews
export const addReview = ({ id, content, rating }) => {
	return api.post(`/student/review/${id}`, {
		content: content,
		rating: rating,
	});
};

export const editReview = ({ id, content, rating }) => {
	return api.patch(`/student/review/${id}`, {
		content: content,
		rating: rating,
	});
};

export const deleteReview = (id) => {
	return api.delete(`/student/review/${id}`);
};

export const getAllReviews = (courseId, page, limit) => {
	return api.get(`/app/allReviews/${courseId}`, {
		params: {
			page,
			limit,
		},
	});
};

export const submitAssignment = (data) =>
	api.post("assignment/student/", data, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});

export const markAsComplete = ({ lessonId, courseId }) => api.post(`/student/addLessonId`, {}, { params: { lessonId, courseId } });

export const getCourseProgress = (id) => api.get(`/student/progress/${id}`);

export const getSubmissionState = (id) => api.get(`/assignment/student/${id}`);
