import { useAppContext } from "context/AppContext";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const Redirect = ({ noAuth }) => {
	const { isLoggedIn, user } = useAppContext();

	if (noAuth) {
		if (isLoggedIn && user) return <Navigate to="/" />;
	} else {
		if (!isLoggedIn || !user) return <Navigate to="/login" />;
	}

	return <Outlet />;
};

export default Redirect;
