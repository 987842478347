import React from "react";
import MarketPlaceResults from "./MarketPlaceResults";
import MarketPlaceSideNavAccordion from "./MarketPlaceSideNavAccordion";
import { useSearchParams } from "react-router-dom";
import { filterCourses, getCategories } from "api";
import { useQueries } from "@tanstack/react-query";
import { Helmet } from "react-helmet";

const limit = 10;

const MarketPlace = () => {
	const [searchParams] = useSearchParams();

	const search = searchParams.get("search");
	const rating = searchParams.get("rating");
	const page = searchParams.get("page") || 1;
	const category = searchParams.get("category");
	const courseLevel = searchParams.get("courseLevel");

	const [data, categories] = useQueries({
		queries: [
			{
				queryKey: ["search", search, rating, category, courseLevel, page, limit],
				queryFn: () => filterCourses(search, rating, category, courseLevel, page, limit),
			},
			{
				queryKey: ["categories"],
				queryFn: () => getCategories({ all: 1 }),
			},
		],
	});

	const courseData = data?.data;

	let searchResult;
	if (searchParams.has("search") && data?.result >= 1) {
		searchResult = ` ${data?.result} results for “${search}”`;
	} else if (searchParams.has("search") && data?.error?.message === "No Course Found") {
		searchResult = ` 0 results for “${search}”`;
	} else {
		searchResult = "";
	}

	return (
		<div className="w-full max-w-[1440px] mx-auto px-[3rem]">
			<Helmet>
				<meta charSet="utf-8" />
				<title>Classcube Pro | Courses </title>
			</Helmet>
			<h6 className="text-[#2A3037] font-bold text-[24px] mt-10 mb-20">{searchResult}</h6>
			<div className="my-10 search-page">
				<MarketPlaceSideNavAccordion ratings={data} categories={categories?.data?.data?.categories} />
				<MarketPlaceResults data={courseData} limit={limit} isLoading={data?.isLoading} />
			</div>
		</div>
	);
};
export default MarketPlace;
