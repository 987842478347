import React, { useState } from "react";
import { ArrowLeft, ArrowRight2, ThumbsUpIcon } from "assets/svg";
import Review from "./Review";
import Modal from "components/Modal";
import RatingModal from "modal/RatingModal";
import { useQuery } from "@tanstack/react-query";
import { getAllReviews } from "api";
import { ReviewsSkeleton } from "skeleton";
import StarRatings from "react-star-ratings";
import { formatNumber } from "assets/functions";
import { useSearchParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import generalClasses from "../../general.module.css";

const CourseReviews = ({ courseId }) => {
	const [isOpen, setIsOpen] = useState(false);

	const [searchParams, setSearchParams] = useSearchParams();
	const page = searchParams.get("page") || 1;
	const limit = 10;

	const { isLoading, data, refetch } = useQuery({
		queryKey: ["reviews", courseId, page, limit],
		queryFn: () => getAllReviews(courseId, page, limit),
	});

	const pageCount = Math.ceil(data?.data?.totalReviews / limit);

	const handlePageClick = ({ selected }) => {
		searchParams.set("page", selected + 1);
		setSearchParams(searchParams);
	};

	if (isLoading) {
		return (
			<>
				<div className="pt-8 max-w-full md:max-w-[650px] lg:max-w-[900px]">
					<ReviewsSkeleton count={5} />
				</div>
			</>
		);
	}

	return (
		<>
			<div className="pt-8 max-w-full md:max-w-[650px] lg:max-w-[900px]">
				<button className="flex items-center gap-2 text-white rounded-md bg-accent px-[15px] py-[12px] mb-20" onClick={() => setIsOpen(true)}>
					<ThumbsUpIcon className="stroke-white" /> Leave a review
				</button>

				{!!parseInt(data?.data?.courseReviews.length) ? (
					<>
						<div className="grid grid-cols-[repeat(auto-fit,_minmax(200px,_1fr))] gap-10 mb-20">
							<div>
								<h3 className="text-black text-[2rem] font-bold mb-4">Total Reviews</h3>
								<p className="text-grey text-[2.6rem] font-bold mb-3">{formatNumber(data?.data?.totalReviews)}</p>
							</div>
							<div>
								<h3 className="text-black text-[2rem] font-bold mb-4">Average Rating</h3>
								<div className="flex items-center gap-4 mb-3">
									<p className="text-grey text-[2.6rem] font-bold">{data?.data?.averageRating.toFixed(1)}</p>
									<StarRatings rating={data?.data?.averageRating} starRatedColor="#FBBF24" starDimension="25px" starSpacing="3px" />
								</div>
							</div>
						</div>

						<h3 className="text-black font-bold text-[2rem] mb-5">Reviews</h3>
					</>
				) : (
					<>
						<div className="flex flex-col items-center justify-center gap-5">
							<p className="text-grey text-[1.7rem] md:max-w-[705px] font-medium">There are no reviews.</p>
						</div>
					</>
				)}

				<>
					{data?.data?.courseReviews?.map((review, index) => (
						<Review key={index} data={review} />
					))}

					{!!parseInt(data?.data?.courseReviews.length) && (
						<ReactPaginate
							nextLabel={
								<span className="flex items-center gap-1">
									Next <ArrowRight2 />
								</span>
							}
							onPageChange={handlePageClick}
							pageRangeDisplayed={3}
							marginPagesDisplayed={2}
							pageCount={pageCount}
							previousLabel={
								<span className="flex items-center gap-1">
									<ArrowLeft /> Previous
								</span>
							}
							pageClassName="page-item"
							pageLinkClassName={generalClasses.pageLInk}
							previousClassName="page-item"
							previousLinkClassName={generalClasses.pageLInk}
							nextClassName="page-item"
							nextLinkClassName={generalClasses.pageLInk}
							breakLabel="..."
							breakClassName="page-item"
							breakLinkClassName={generalClasses.pageLInk}
							containerClassName={generalClasses.pagination}
							activeClassName={generalClasses.active}
							renderOnZeroPageCount={null}
							forcePage={parseInt(searchParams.get("page") || 1) - 1}
						/>
					)}
				</>
			</div>
			<Modal isOpen={isOpen} setIsOpen={setIsOpen} size={"large"}>
				<RatingModal setIsOpen={setIsOpen} refetch={refetch} courseId={courseId} />
			</Modal>
		</>
	);
};

export default CourseReviews;
