import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parse, subDays } from "date-fns";
import { ErrorMessage, Formik, useFormikContext } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import * as Yup from "yup";
import avatar from "assets/avatar.png";
import { useDropzone } from "react-dropzone";
import { useCallback } from "react";
import { useAppContext } from "context/AppContext";
import { useMutation } from "@tanstack/react-query";
import { updatestudentprofile } from "api";
import { isValidFileType, renderSuccessMessage } from "assets/functions";
import { MAX_FILE_SIZE } from "assets/constants";

const PersonalInfo = () => {
	const { user, setUser } = useAppContext();

	const initialValues = {
		firstName: user?.firstName ?? "",
		lastName: user?.lastName ?? "",
		email: user?.email ?? "",
		phoneNumber: user?.phoneNumber ?? "",
		dob: user?.dob ?? format(new Date(), "dd/MM/yyyy"),
		profilePic: user?.profilePic ?? null,
	};
	const validationSchema = Yup.object().shape({
		firstName: Yup.string().required("Please provide your firstname"),
		lastName: Yup.string().required("Please provide your lastname"),
		phoneNumber: Yup.string().required("Please provide your phone number"),
		email: Yup.string().email("Please provide a valid email").required("Please provide a valid email"),
		dob: Yup.string().required("Please provide your date of birth"),
		profilePic: Yup.mixed()
			.required("Required")
			.test("is-valid-type", "Not a valid image type", (value) => {
				if (typeof value === "string" || value instanceof String) {
					return true;
				} else {
					return isValidFileType(value && value.name.toLowerCase(), "courseImg");
				}
			})
			.test("is-valid-size", "Max allowed size is 500KB", (value) => {
				if (typeof value === "string" || value instanceof String) {
					return true;
				} else {
					return value && value.size <= MAX_FILE_SIZE;
				}
			}),
	});

	const { mutate, isLoading } = useMutation(updatestudentprofile, {
		onSuccess: (data) => {
			renderSuccessMessage(data?.message);
			setUser(data?.updatedUser[1][0]);
		},
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={({ email, ...values }) => {
				// if image is alreday upload an no new image is added remove image from request
				if (typeof values.profilePic === "string" || values.profilePic instanceof String) {
					delete values["profilePic"];
				}

				let formData = new FormData();
				Object.entries(values).forEach(([key, value]) => {
					if (Array.isArray(value)) {
						value.forEach((item) => {
							formData.append(key, item);
						});
					} else {
						formData.append(key, value);
					}
				});
				mutate(formData);
			}}
		>
			{({ values, handleChange, setFieldValue, handleSubmit }) => (
				<form className="max-w-[900px] flex flex-col" onSubmit={handleSubmit}>
					<AvatarDropzone />
					<ErrorMessage name="profilePic" component="div" className="text-red-500 text-[1rem] mb-[20px]" />
					<div className="form-group ">
						<label className="font-medium text-[1.4rem] text-[#47505B]">Email Address</label>
						<input
							type="text"
							className="control-form border color-[#F2F4F7]"
							name="email"
							onChange={handleChange}
							value={values.email}
							disabled
						/>
						<ErrorMessage name="email" component="div" className="text-red-500 text-[1rem]" />
					</div>
					<div className="">
						<div className="form-group">
							<label className="font-medium text-[1.4rem] text-[#47505B]">Firstname</label>
							<input
								type="text"
								className="control-form border color-[#F2F4F7]"
								name="firstName"
								onChange={handleChange}
								value={values.firstName}
							/>
							<ErrorMessage name="firstName" component="div" className="text-red-500 text-[1rem]" />
						</div>
					</div>
					<div className="">
						<div className="form-group">
							<label className="font-medium text-[1.4rem] text-[#47505B]">Lastname</label>
							<input
								type="text"
								className="control-form border color-[#F2F4F7]"
								name="lastName"
								onChange={handleChange}
								value={values.lastName}
							/>
							<ErrorMessage name="lastName" component="div" className="text-red-500 text-[1rem]" />
						</div>
					</div>

					<div className="form-group ">
						<label className="font-medium text-[1.4rem] text-[#47505B]">Phone No</label>
						<PhoneInput
							country={"ng"}
							name="phoneNumber"
							value={values.phoneNumber}
							inputStyle={{ width: "100%", height: "50px", fontSize: "1.5rem" }}
							onChange={(value) => setFieldValue("phoneNumber", value)}
						/>
						<ErrorMessage name="phoneNumber" component="div" className="text-red-500 text-[1rem]" />
					</div>
					{/* <div className="form-group ">
                        <label className="font-medium text-[1.4rem] text-[#47505B]">
                            Gender
                        </label>
                        <select
                            name="gender"
                            className="control-form border color-[#F2F4F7]"
                            onChange={handleChange}
                            value={values.gender}
                        >
                            <option value="">Select Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </select>
                        <ErrorMessage
                            name="gender"
                            component="div"
                            className="text-red-500 text-[1rem]"
                        />
                    </div> */}
					<div className="form-group ">
						<label className="font-medium text-[1.4rem] text-[#47505B]">Date of Birth</label>
						<DatePicker
							className="control-form"
							selected={parse(values.dob, "dd/MM/yyyy", new Date())}
							onChange={(date) => setFieldValue("dob", format(date, "dd/MM/yyyy"))}
							maxDate={subDays(new Date(), 0)}
							dateFormat="dd/MM/yyyy"
							showMonthDropdown
							showYearDropdown
						/>
						<ErrorMessage name="dob" component="div" className="text-red-500 text-[1rem]" value={values.dob} />
					</div>

					<button type="submit" disabled={isLoading} className="btn-form-primary">
						Save
					</button>
				</form>
			)}
		</Formik>
	);
};

const AvatarDropzone = () => {
	const { setFieldValue, values } = useFormikContext();

	const onDrop = useCallback(
		(acceptedFiles) => {
			setFieldValue(
				"profilePic",
				acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)[0]
			);
		},
		[setFieldValue]
	);

	const { getRootProps, getInputProps } = useDropzone({
		multiple: false,
		onDrop,
	});

	return (
		<div className="border border-[#F2F4F7] p-[15px] rounded-md flex items-center gap-3 cursor-pointer mb-[15px]" {...getRootProps()}>
			<input {...getInputProps()} aria-label="avatar-upload" />
			{!values.profilePic ? (
				<img src={avatar} alt="user-avatar" className="rounded-full w-[100px] aspect-square" />
			) : typeof values.profilePic === "string" || values.profilePic instanceof String ? (
				<img src={`https://${values?.profilePic}`} alt="user-avatar" className="rounded-full w-[100px] aspect-square" />
			) : (
				<img src={values?.profilePic?.preview} alt="user-avatar" className="rounded-full w-[100px] aspect-square" />
			)}
			<div>
				<h4 className="text-[#0c78f1] text-[1.6rem] mb-2 font-medium">Upload Profile Photo</h4>
				<p className="text-[#96A1AE] text-[1.2rem]">max 800x400 px</p>
			</div>
		</div>
	);
};

export default PersonalInfo;
