import { ArrowDownIcon, ArrowUpIcon, DocumentIcon } from "assets/svg";
import React from "react";
import {
	Accordion,
	AccordionItem,
	AccordionItemButton,
	AccordionItemHeading,
	AccordionItemPanel,
	AccordionItemState,
} from "react-accessible-accordion";
import { default as CustomPopup } from "components/Popup";
import { pluralize, renderSuccessMessage } from "assets/functions";
import { useMutation } from "@tanstack/react-query";
import { markAsComplete } from "api";

const LessonsAccordion = ({ lessons: rawChapters, setSelectedLesson, selectedLesson, refetch, completedLessons }) => {
	const lessons = rawChapters?.sort((chapter1, chapter2) => chapter1.sectionOrder - chapter2.sectionOrder);

	return (
		<Accordion allowZeroExpanded allowMultipleExpanded>
			{lessons?.map((lessonGroup, index) => (
				<AccordionItem key={index} className="accordion__item border-b border-b-[#e7e5ea]">
					<AccordionItemHeading>
						<AccordionItemButton>
							<div className="flex items-center justify-between mb-0 text-[1.6rem] font-bold">
								{lessonGroup?.name}
								<AccordionItemState>{({ expanded }) => (expanded ? <ArrowUpIcon /> : <ArrowDownIcon />)}</AccordionItemState>
							</div>
							<p className="text-grey text-[1.2rem] font-light">{pluralize(lessonGroup?.lessons.length, "lesson")}</p>
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel className={`accordion__panel !px-[10px] panel-${lessonGroup.id}`}>
						{lessonGroup?.lessons
							?.sort((lesson1, lesson2) => lesson1.lessonOrder - lesson2.lessonOrder)
							?.map((lesson, index) => (
								<Lesson
									key={index}
									lesson={lesson}
									index={index}
									selectedLesson={selectedLesson}
									setSelectedLesson={setSelectedLesson}
									lessonGroup={lessonGroup}
									refetch={refetch}
									completedLessons={completedLessons}
								/>
							))}
					</AccordionItemPanel>
				</AccordionItem>
			))}
		</Accordion>
	);
};

const Lesson = ({ lesson, setSelectedLesson, selectedLesson, refetch, lessonGroup, completedLessons }) => {
	const { mutate } = useMutation(markAsComplete, {
		onSuccess: ({ message }) => {
			renderSuccessMessage(message).then(() => refetch());
		},
	});

	const handleChange = (event) => {
		if (event.target.checked) {
			mutate({ lessonId: lesson.id, courseId: lessonGroup.courseId });
		} else {
			return;
		}
	};

	return (
		<div className="mb-4">
			<div className="flex gap-4 mb-2">
				<input type="checkbox" className="cursor-pointer" onChange={handleChange} checked={completedLessons?.includes(lesson.id)} />
				<button
					className={`${
						selectedLesson?.id === lesson.id ? "text-primary" : "text-black"
					} text-[1.4rem] text-left font-medium !outline-none border-none flex gap-2`}
					onClick={() => setSelectedLesson(lesson)}
				>
					<span className="font-bold">{lesson.name}</span>
				</button>
			</div>

			{lesson?.attachment.length > 0 && lesson?.attachment[0] !== "" && (
				<div className="flex items-center justify-between">
					<CustomPopup
						trigger={
							<button className={`flex items-center gap-2 border border-[#E7E5EA] rounded-sm mt-2 py-[2px] px-[4px]`}>
								<DocumentIcon />
								<p className="text-grey text-[1.2rem]">Download Resource</p>
							</button>
						}
						arrow={false}
						closeOnDocumentClick
						position={["bottom center", "bottom right", "bottom left"]}
						keepTooltipInside={`.panel-${lessonGroup.id}`}
						width="fit-content"
						childrenArray={lesson?.attachment?.map((attachment) => ({
							text: <LessonAttachement attachment={attachment} />,
							onClick: () => {},
						}))}
					/>
				</div>
			)}
		</div>
	);
};

export const LessonAttachement = ({ attachment }) => {
	const extension = attachment ? attachment.split(".").slice(-1) : "";
	const names = attachment ? attachment.split(".").slice(0, -1).join(".").split("~~~")[0].split("/")[1].toUpperCase() : "";

	return (
		attachment && (
			<a href={`https://${attachment}`} download={true} className="text-[1.35rem]">
				{names?.concat(".", extension)}
			</a>
		)
	);
};

export default LessonsAccordion;
