import React, { useState } from "react";
import logo from "assets/logo.png";
// import background_banner from "assets/student-side-img.png";
import background_banner1 from "assets/student-side-img2.png";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useMutation } from "@tanstack/react-query";
import { signUp } from "../../api";
import { useAppContext } from "context/AppContext";
import { renderErrorMessage } from "assets/functions";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const StudentSignUp = () => {
	const { setUser, setCookie } = useAppContext();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const [visible, setVisible] = useState(false);
	const [visible1, setVisible1] = useState(false);

	const registerSchema = yup.object().shape({
		firstName: yup.string().required("Please provide your first name"),
		lastName: yup.string().required("Please provide your last name"),
		phoneNumber: yup.string().required("Please provide your phone number"),
		email: yup.string().email("Please provide a valid email").required("Please provide a valid email"),
		password: yup
			.string()
			.required("Please provide password")
			.min(8, "Password must be 8 characters long")
			.matches(/[0-9]/, "Password requires a number")
			.matches(/[a-z]/, "Password requires a lowercase letter")
			.matches(/[A-Z]/, "Password requires an uppercase letter")
			.matches(/[^\w]/, "Password requires a symbol"),
		confirmPassword: yup
			.string()
			.required("Confirm your password")
			.oneOf([yup.ref("password"), null], "Password don't match"),
	});

	const initialValues = {
		firstName: "",
		lastName: "",
		email: "",
		password: "",
		confirmPassword: "",
		phoneNumber: "",
	};

	const { mutate, isLoading } = useMutation(signUp, {
		onSuccess: (data) => {
			setUser(data?.data?.user);
			setCookie("jwt", data?.token);
			if (searchParams.has("redirect")) {
				navigate(`/courses/${searchParams.get("redirect")}`);
			}
		},
		onError: (error) => renderErrorMessage(error.message),
	});

	return (
		<div>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Classcube Pro | Register </title>
			</Helmet>
			<div className=" row justify-center align-center h-[100vh]">
				<Formik initialValues={initialValues} validationSchema={registerSchema} onSubmit={({ confirmPassword, ...values }) => mutate(values)}>
					{({ values, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
						<>
							<div className="h-full scrollmenot col-lg-6">
								<div className="flex items-center justify-center mt-[50px] mb-[30px]">
									<img src={logo} className="w-[160px] h-[40px]" alt="Logo" />
								</div>
								<h1 className="text-center text-[#0c78f1] text-[4.6rem] font-[700] mb-[15px] font-">Create an account</h1>
								<p className="text-center text-[#5E6C85] text-[2rem] font-[400]">Please enter your details.</p>
								<form className="w-[90%] max-w-[500px] mr-[auto] ml-[auto] mt-[30px]  mb-[100px]" onSubmit={handleSubmit}>
									<div className="form-group mb-[20px]">
										<label className="font-[500px] text-[1.4rem] text-black">First Name</label>
										<input
											type="text"
											className="border control-form color-darkGrey"
											placeholder="Enter your first name"
											name="firstName"
											onChange={handleChange}
											value={values.firstName}
											onBlur={handleBlur}
										/>
										<ErrorMessage name="firstName" component="div" className="text-red-500 text-[1.4rem]" />
									</div>
									<div className="form-group mb-[20px]">
										<label className="font-[500px] text-[1.4rem] text-black">Last Name</label>
										<input
											type="text"
											className="border control-form color-darkGrey"
											placeholder="Enter your last name"
											name="lastName"
											onChange={handleChange}
											value={values.lastName}
											onBlur={handleBlur}
										/>
										<ErrorMessage name="lastName" component="div" className="text-red-500 text-[1.4rem]" />
									</div>
									<div className="form-group mb-[20px]">
										<label className="font-[500px] text-[1.4rem] text-black">Email Address</label>
										<input
											type="text"
											className="border control-form color-darkGrey"
											name="email"
											placeholder="Enter your Email Address"
											onChange={handleChange}
											value={values.email}
											onBlur={handleBlur}
										/>
										<ErrorMessage name="email" component="div" className="text-red-500  text-[1.4rem]" />
									</div>

									<div className="form-group mb-[20px]">
										<label className="font-[500px] text-[1.4rem] text-black">Phone Number</label>
										<PhoneInput
											placeholder="Enter phone number"
											value={values.phoneNumber}
											country={"ng"}
											inputStyle={{ width: "100%", height: "50px", fontSize: "1.5rem", borderColor: "#dee2e6" }}
											onChange={(phone) => setFieldValue("phoneNumber", phone)}
											type="phoneNumber"
											name="phoneNumber"
											onBlur={handleBlur}
										/>
										<ErrorMessage name="phoneNumber" component="div" className="text-red-500 text-[1.4rem]" />
									</div>

									<div className="form-group mb-[20px]">
										<label className="font-[500px] text-[1.4rem] text-black">Password</label>
										<div className="has-pwd-icon">
											<input
												className="border control-form color-darkGrey"
												placeholder="Enter your password"
												type={visible ? "text" : "password"}
												name="password"
												onChange={handleChange}
												value={values.password}
												onBlur={handleBlur}
											/>
											{visible ? (
												<BsEye className="view-pwd" size={20} onClick={() => setVisible((prevState) => !prevState)} />
											) : (
												<BsEyeSlash className="view-pwd" size={20} onClick={() => setVisible((prevState) => !prevState)} />
											)}
										</div>
										<ErrorMessage name="password" component="div" className="text-red-500 text-[1.4rem]" />
									</div>
									<div className="form-group mb-[20px]">
										<label className="font-[500px] text-[1.4rem] text-black">Confirm Password</label>
										<div className="has-pwd-icon">
											<input
												className="border control-form color-darkGrey"
												name="confirmPassword"
												placeholder="Re-type your password"
												type={visible1 ? "text" : "password"}
												onChange={handleChange}
												value={values.confirmPassword}
												onBlur={handleBlur}
											/>
											{visible1 ? (
												<BsEye className="view-pwd" size={20} onClick={() => setVisible1((prevState) => !prevState)} />
											) : (
												<BsEyeSlash className="view-pwd" size={20} onClick={() => setVisible1((prevState) => !prevState)} />
											)}
										</div>
										<ErrorMessage name="confirmPassword" component="div" className="text-red-500 text-[1.4rem]" />
									</div>
									<button
										type="submit"
										className="btn submit-signup w-full bg-[#0c78f1] text-white p-[15px] text-[1.4rem]  mt-[40px]"
										disabled={isLoading}
									>
										Sign Up
									</button>
									<p className="my-[20px] text-center">
										Already have an account?
										<Link
											to={`/login${searchParams.toString() ? `?${searchParams.toString()}` : ""}`}
											className="text-[#0372ce] ml-2 no-underline"
										>
											Log in
										</Link>
									</p>
								</form>
							</div>
							<div className="col-lg-6 background_banner">
								<div className="z-10 isolate">
									<h1 className="courses-you-need">All the Courses You Need in One Place</h1>
									<p className="courses-you-need-sub">
										This should include a clear description of those learners that will find your course valuable.
									</p>
									<img className="background_banner1" src={background_banner1} alt="back" />
								</div>
							</div>
						</>
					)}
				</Formik>
			</div>
		</div>
	);
};
export default StudentSignUp;
