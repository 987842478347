import React, { useEffect, useState } from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { ErrorMessage, Formik } from "formik";
import { useMutation } from "@tanstack/react-query";
import { setPassword } from "api";
import * as yup from "yup";
import { Helmet } from "react-helmet";
import { renderErrorMessage, renderSuccessMessage } from "assets/functions";
import logo from "assets/logo.png";

const validationSchema = Yup.object().shape({
	password: yup
		.string()
		.required("Password is required")
		.min(8, "Password must be 8 characters long")
		.matches(/[0-9]/, "Password requires a number")
		.matches(/[a-z]/, "Password requires a lowercase letter")
		.matches(/[A-Z]/, "Password requires an uppercase letter")
		.matches(/[^\w]/, "Password requires a symbol"),
	password1: yup
		.string()
		.required("Confirm your password")
		.oneOf([yup.ref("password"), null], "Passwords don't match"),
});

const initialValues = {
	password: "",
	password1: "",
};

const SetPassword = () => {
	const [visible, setVisible] = useState(false);
	const [visible1, setVisible1] = useState(false);
	const [token, setToken] = useState("");
	const navigate = useNavigate();

	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		if (searchParams.get("token")) setToken(searchParams.get("token"));

		searchParams.delete("token");
		setSearchParams(searchParams);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { mutate, isLoading } = useMutation(setPassword, {
		onSuccess: () => renderSuccessMessage("Password reset sucessfully").then(() => navigate("/login")),
		onError: ({ message }) => renderErrorMessage(message),
	});

	return (
		<div>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Classcube Pro | Set New Password </title>
			</Helmet>
			<div className="flex items-center justify-center mt-[100px] mb-[30px]">
				<img src={logo} className="w-[160px] h-[40px]" alt="Classcube Pro" />
			</div>
			<h1 className="text-center text-[#0372CE] text-[4.6rem] font-[700] mb-[15px] font-">Reset Password</h1>
			<div className="flex justify-center align-center mt-[50px]">
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={({ password1, ...values }) =>
						mutate({
							token: token,
							data: values,
						})
					}
				>
					{({ values, handleChange, handleSubmit }) => (
						<form className="w-[90%] max-w-[500px] mb-[100px]" onSubmit={handleSubmit}>
							<div className="form-group">
								<label className="font-[500px] text-[1.4rem] text-black">New Password</label>
								<div className="has-pwd-icon">
									<input
										className="control-form border color-[#F2F4F7]"
										placeholder="Type your password"
										onChange={handleChange}
										type={visible ? "text" : "password"}
										name="password"
										value={values.password}
									/>
									{visible ? (
										<BsEye className="view-pwd" size={20} onClick={() => setVisible((prevState) => !prevState)} />
									) : (
										<BsEyeSlash className="view-pwd" size={20} onClick={() => setVisible((prevState) => !prevState)} />
									)}
								</div>
								<ErrorMessage name="password" component="div" className="text-red-500" />
							</div>
							<div className="form-group">
								<label className="font-[500px] text-[1.4rem] text-black">Confirm Password</label>
								<div className="has-pwd-icon">
									<input
										className="control-form border color-[#F2F4F7]"
										placeholder="Re-type your password"
										onChange={handleChange}
										type={visible1 ? "text" : "password"}
										name="password1"
										value={values.password1}
									/>
									{visible1 ? (
										<BsEye className="view-pwd" size={20} onClick={() => setVisible1((prevState) => !prevState)} />
									) : (
										<BsEyeSlash className="view-pwd" size={20} onClick={() => setVisible1((prevState) => !prevState)} />
									)}
								</div>
								<ErrorMessage name="password1" component="div" className="text-red-500" />
							</div>

							<button
								type="submit"
								disabled={isLoading}
								className="btn submit-signup w-full bg-[#0c78f1]  text-white p-[15px] text-[1.4rem]  mt-[40px]"
							>
								Reset Password
							</button>
						</form>
					)}
				</Formik>
			</div>
		</div>
	);
};
export default SetPassword;
