import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getUserNotifications } from "api";
import { ArrowLeft, ArrowRight2, ExitIcon } from "assets/svg";
import { Helmet } from "react-helmet";

import ReactPaginate from "react-paginate";
import generalClasses from "./general.module.css";
import { useSearchParams } from "react-router-dom";

const limit = 3;
const Notifications = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const page = searchParams.get("page") || 1;

	const { data } = useQuery({
		queryKey: ["notifications", page, limit],
		queryFn: () => getUserNotifications(page, limit),
		suspense: true,
	});

	const pageCount = Math.ceil(data?.data?.notifications?.count / limit);

	const handlePageClick = ({ selected }) => {
		searchParams.set("page", selected + 1);
		setSearchParams(searchParams);
	};

	return (
		<div className="flex flex-col min-h-[80%] justify-between max-w-[1440px] w-full mx-auto  px-[3rem]">
			<Helmet>
				<meta charSet="utf-8" />
				<title>Classcube Pro | Notifications </title>
			</Helmet>
			<div className="max-w-[900px] flex flex-col">
				<h1 className="text-[2.8rem] text-[#2A3037] font-bold mb-[5rem] mt-[2rem]">Notifications</h1>
				{data?.data?.notifications?.rows.map((notification) => (
					<Notification notification={notification} key={notification.id} />
				))}
			</div>
			{data?.data?.notifications?.rows.length > 0 && (
				<ReactPaginate
					nextLabel={
						<span className="flex items-center gap-1">
							Next <ArrowRight2 />
						</span>
					}
					onPageChange={handlePageClick}
					pageRangeDisplayed={3}
					marginPagesDisplayed={2}
					pageCount={pageCount}
					previousLabel={
						<span className="flex items-center gap-1">
							<ArrowLeft />
							Previous{" "}
						</span>
					}
					pageClassName="page-item"
					pageLinkClassName={generalClasses.pageLink}
					previousClassName="page-item"
					previousLinkClassName={generalClasses.pageLink}
					nextClassName="page-item"
					nextLinkClassName={generalClasses.pageLink}
					breakLabel="..."
					breakClassName="page-item"
					breakLinkClassName={generalClasses.pageLink}
					containerClassName={generalClasses.pagination}
					activeClassName={generalClasses.active}
					renderOnZeroPageCount={null}
					forcePage={parseInt(searchParams.get("page") || 1) - 1}
				/>
			)}
		</div>
	);
};

const Notification = ({ notification }) => {
	return (
		<div className="flex gap-4 mb-8 items-start border-b border-b-[#E7E5EA] pb-5 pl-5">
			<div className="w-[30px] rounded-full relative">
				<img src={notification?.image} alt="user-avatar" className="w-full aspect-square" />
				{/* {!notification?.isRead && <div className="w-[10px] aspect-square bg-[#0C78F1] rounded-full absolute top-0 left-0" />} */}
			</div>
			<div>
				<p className="text-[#5E6C85] text-[1.6rem] font-extrabold mb-2">{notification.header}</p>
				<p className="text-[#5E6C85] text-[1.4rem]">{notification.body}</p>
				{!!notification.redirectUrl && (
					<a
						href={notification.redirectUrl}
						target="_blank"
						rel="noreferrer"
						className="flex items-center gap-1 text-[#5E6C85] text-[1.2rem]"
					>
						Go <ExitIcon />
					</a>
				)}
			</div>
		</div>
	);
};

export default Notifications;
