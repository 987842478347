import React from "react";
import StarRatings from "react-star-ratings";
import { Link, useSearchParams } from "react-router-dom";
import { calculateDiscountPrice, renderCurrency } from "assets/functions";
import LinesEllipsis from "react-lines-ellipsis/lib/html";
import sanitizeHtml from "sanitize-html";
import { sanitizeHtmlOption } from "assets/constants";
import Loader from "components/Loader";
import ReactPaginate from "react-paginate";
import { ArrowLeft, ArrowRight2 } from "assets/svg";
import generalClasses from "../general.module.css";

const MarketPlaceResults = ({ data, isLoading, limit }) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const pageCount = Math.ceil(data?.totalCount / limit);
	const courses = data?.data?.courses;

	const handlePageClick = ({ selected }) => {
		searchParams.set("page", selected + 1);
		setSearchParams(searchParams);
	};

	if (isLoading) return <Loader />;

	return (
		<>
			<div
				className="mb-3 a-card courses-card gap-10 min-h-[80vh] justify-between"
				style={{ borderLeft: "solid 1px #d7d7d7", paddingLeft: "3rem" }}
			>
				<div>
					{!!courses?.length ? (
						courses?.map((course, index) => (
							<Link to={`/courses/${course.slug}`}>
								<div className="mb-5 card-container no-gutters" key={index}>
									<div className="courses-card-img">
										<img src={`https://${course?.courseImg}`} className="card-img h-[200px] object-contain" alt="..." />
									</div>
									<div className="a-card-body">
										<div className="d-flex max-[630px]:flex-col justify-content-between">
											<h2 className="a-card-title !m-0 hover:text-primaryColor">{course.name}</h2>
											{!!course?.discount && !!parseInt(course?.discount) && !!course?.price ? (
												<>
													<div>
														<span className="line-through text-[1.4rem] text-grey mr-3">
															{renderCurrency(course?.price)}
														</span>
														<span className="text-black text-[1.4rem] font-bold ">
															{renderCurrency(calculateDiscountPrice(course?.price, course?.discount))}
														</span>
													</div>
												</>
											) : (
												<span className="text-black text-[1.4rem] font-bold mr-3">
													{!!parseInt(course?.price) ? renderCurrency(course?.price) : "Free"}
												</span>
											)}
										</div>
										<LinesEllipsis
											unsafeHTML={sanitizeHtml(course?.description, sanitizeHtmlOption)}
											className="a-card-description"
											maxLine="2"
											ellipsis="..."
											basedOn="letters"
										/>
										<p className="text-grey text-[1.3rem] my-1 font-medium">{course.creator.fullName}</p>

										<div className="card-text">
											<div className="flex items-center gap-2">
												<p className="text-[#E59819] text-[1.4rem] font-medium leading-none mt-2">
													{course?.averageRating}
												</p>
												<StarRatings
													rating={course?.averageRating ? parseFloat(course?.averageRating) : 0}
													starRatedColor="#FBBF24"
													starDimension="15px"
													starSpacing="3px"
												/>
											</div>
										</div>
										{course.language}
									</div>
								</div>
							</Link>
						))
					) : (
						<>
							<div className="flex flex-col items-center justify-center gap-5">
								<p className="text-primaryColor text-[2rem] md:max-w-[705px] font-bold">No courses Found</p>
								<p className="text-primaryColor text-[2rem] md:max-w-[705px] font-bold">Try adjusting your search</p>
							</div>
						</>
					)}
				</div>

				{!!courses?.length && (
					<ReactPaginate
						nextLabel={
							<span className="flex items-center gap-1">
								Next <ArrowRight2 />
							</span>
						}
						onPageChange={handlePageClick}
						pageRangeDisplayed={3}
						marginPagesDisplayed={2}
						pageCount={pageCount}
						previousLabel={
							<span className="flex items-center gap-1">
								<ArrowLeft /> Previous{" "}
							</span>
						}
						pageClassName="page-item"
						pageLinkClassName={generalClasses.pageLInk}
						previousClassName="page-item"
						previousLinkClassName={generalClasses.pageLInk}
						nextClassName="page-item"
						nextLinkClassName={generalClasses.pageLInk}
						breakLabel="..."
						breakClassName="page-item"
						breakLinkClassName={generalClasses.pageLInk}
						containerClassName={generalClasses.pagination}
						activeClassName={generalClasses.active}
						renderOnZeroPageCount={null}
						forcePage={parseInt(searchParams.get("page") || 1) - 1}
					/>
				)}
			</div>
		</>
	);
};

export default MarketPlaceResults;
