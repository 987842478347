import { ArrowRightIcon } from "assets/svg";
import { Link } from "react-router-dom";
import LinesEllipsis from "react-lines-ellipsis";
import StarRatings from "react-star-ratings";
import { calculateDiscountPrice, renderCurrency } from "../assets/functions";
import { CardSkeleton } from "skeleton";

const CoursesGrid = ({ title, isLoading, data }) => {
	const courses = data?.data?.courses;

	return (
		<div>
			<div className="flex items-end gap-10 mb-20">
				<h2 className="text-[2.8rem] font-medium text-black">{title}</h2>
				<button className="flex gap-2 text-accent text-[1.6rem] items-center font-medium mb-1 d-none">
					See All <ArrowRightIcon />
				</button>
			</div>
			<div className="grid md:grid-cols-[repeat(auto-fill,_minmax(300px,1fr))] sm:grid-cols-[repeat(auto-fit,_minmax(250px,1fr))] grid-cols-[repeat(auto-fit,_minmax(200px,1fr))] gap-14 mb-32">
				{isLoading && <CardSkeleton count={8} />}

				{courses?.map((course, index) => (
					<CourseCell key={index} course={course} />
				))}
			</div>
		</div>
	);
};

const CourseCell = ({ course }) => {
	const ratings = course?.averageRating ? parseFloat(course?.averageRating) : 0;

	return (
		<div className="overflow-hidden rounded-lg">
			<Link className="w-full" to={`/courses/${course?.slug}`}>
				<img src={`https://${course?.courseImg}`} className="w-full h-[200px] object-cover" alt="course" />
			</Link>
			<div className="p-[12px] [&>*]:mb-2">
				<Link className="block" to={`/courses/${course?.slug}`}>
					<LinesEllipsis
						text={course?.name}
						className="text-black text-[1.7rem] capitalize font-bold"
						maxLine="2"
						ellipsis="..."
						basedOn="letters"
					/>
				</Link>
				<p className="text-grey text-[1.4rem] capitalize">{course?.creator?.fullName}</p>
				<div className="flex items-center gap-2">
					<p className="text-[#E59819] text-[1.4rem] font-medium leading-none mt-2">{ratings.toFixed(1)}</p>
					<StarRatings rating={ratings} starRatedColor="#FBBF24" starDimension="15px" starSpacing="3px" />
				</div>
				{!!course?.discount && !!parseInt(course?.discount) && !!course?.price ? (
					<>
						<span className="text-black text-[1.4rem] font-bold mr-3">
							{renderCurrency(calculateDiscountPrice(course?.price, parseInt(course?.discount)))}
						</span>
						<span className="line-through text-[1.4rem] text-grey">{renderCurrency(course?.price)}</span>
					</>
				) : (
					<span className="text-black text-[1.4rem] font-bold mr-3">
						{!!parseInt(course?.price) ? renderCurrency(course?.price) : "Free"}
					</span>
				)}
			</div>
		</div>
	);
};

export default CoursesGrid;
