import { createContext, useContext, useEffect, useState } from "react";
import { getFromStorage, setToStorage } from "assets/functions";
import { useCookies } from "react-cookie";

const AppContext = createContext({
	user: "",
	setUser: () => {},
	isLoggedIn: false,
	setCookie: () => {},
});

const AppProvider = ({ children }) => {
	const [user, setUser] = useState(getFromStorage("student-user") ?? null);
	const [cookies, setCookie] = useCookies(["jwt"]);

	useEffect(() => {
		setToStorage("student-user", user);
	}, [user]);

	return <AppContext.Provider value={{ user, setUser, isLoggedIn: !!cookies.jwt, setCookie }}>{children}</AppContext.Provider>;
};

export default AppProvider;

export const useAppContext = () => useContext(AppContext);
