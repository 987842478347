import React from "react";
import PersonalInfo from "./PersonalInfo";
import PasswordUpdate from "./PasswordUpdate";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import generalClasses from "../general.module.css";
import "./settings-tabs.css";
import { Helmet } from "react-helmet";

const Settings = () => {
	return (
		<div className="max-w-[1440px] w-full mx-auto  px-[3rem]">
			<Helmet>
				<meta charSet="utf-8" />
				<title>Classcube Pro | Settings </title>
			</Helmet>
			<div className="my-[50px]">
				<h1 className={generalClasses.pageTitle}>Profile and Settings</h1>
				<Tabs>
					<TabList>
						<Tab>Personal Info</Tab>
						<Tab>Update Password</Tab>
					</TabList>

					<TabPanel>
						<PersonalInfo />
					</TabPanel>
					<TabPanel>
						<PasswordUpdate />
					</TabPanel>
				</Tabs>
			</div>
		</div>
	);
};

export { PersonalInfo, PasswordUpdate };

export default Settings;
